<template>
  <Transition name="slide-fade" mode="out-in">
    <div v-if="profile" class="row justify--center mt-5">
      <div class="flex md3 xs12" style="padding: 0">
        <va-card
          gradient
          color="#003566"
          style="height: 400px; border-radius: 0"
        >
          <va-card-content
            class="col justify--center align--center"
            style="height: 400px"
          >
            <va-avatar style="width: 100px; height: 100px">A</va-avatar>
            <div class="mt-3 text-light text--center">
              <h2 class="text-large">{{ userData?userData.name: "" }}</h2>
              <h4 class="mt-2">{{ userData?.user_role?userData.user_role.type: "" }}</h4>
            </div>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex md7 xs12" style="padding: 0">
        <va-card style="height: 400px; border-radius: 0" outlined>
          <va-card-title>{{ $t("user.profile") }}</va-card-title>
          <va-card-content>
            <form>
              <va-input
                class="mt-5"
                v-model="userData.name"
                :label="$t('name')"
              />
              <va-input
                class="mt-5"
                v-model="userData.email"
                :label="$t('email')"
              />
              <div class="mt-5 float-start">
                <va-button @click="changeInfo" class="mx-3">{{
                  $t("save")
                }}</va-button>
                <va-button
                  gradient
                  color="info"
                  flat
                  @click="state = 'changePassword'"
                  >{{ $t("auth.change_password") }}</va-button
                >
              </div>
            </form>
          </va-card-content>
        </va-card>
      </div>
    </div>
    <div v-else class="row justify--center mt-5">
      <div class="flex md7 xs12" style="padding: 0">
        <va-card style="height: 400px; border-radius: 0" outlined>
          <va-card-title>{{ $t("user.profile") }}</va-card-title>
          <va-card-content>
            <form>
              <va-input
                class="mt-5"
                v-model="passwords.old"
                :label="$t('auth.old')"
              />
              <va-input
                class="mt-5"
                v-model="passwords.new"
                :label="$t('auth.new')"
              />
              <div class="mt-5 float-end">
                <va-button gradient color="primary">{{ $t("save") }}</va-button>
                <va-button @click="state = 'profile'" flat class="mx-3">{{
                  $t("cancel")
                }}</va-button>
              </div>
            </form>
          </va-card-content>
        </va-card>
      </div>
      <div class="flex md3 xs12" style="padding: 0">
        <va-card
          gradient
          color="#003566"
          style="height: 400px; border-radius: 0"
        >
          <va-card-content
            class="col justify--center align--center"
            style="height: 400px"
          >
            <va-avatar style="width: 100px; height: 100px">A</va-avatar>
            <div class="mt-3 text-light text--center">
              <h2 class="text-large">{{ userData?.name }}</h2>
              <h4 class="mt-2">{{ userData?.user_role?userData.user_role.type: "" }}</h4>
            </div>
          </va-card-content>
        </va-card>
      </div>
    </div>
  </Transition>
</template>

<script>
import { gql, request } from "graphql-request";
export default {
  data() {
    return {
      state: "profile",
      passwords: {},
      userData: {},
    };
  },
    async mounted() {
        this.userData = this.getUserData
    },
  computed: {
    profile() {
      return this.state == "profile" ? true : false;
    },
    getUserData() {
         return this.userData = Object.assign({},this.$store.state.userData)
    },
    // async changePassword(){
    //     const loginMutation = gql`
    //             mutation login($username:String,$password:String){
    //               login(loginInput:{user:$username,password:$password})
    //               {
    //                 id
    //                 token
    //                 status
    //               }
    //             }
    //             `
    //     const response = await request(this.$store.state.appUrl,loginMutation,{
    //         username: this.userData.email,
    //         password: this.passwords.old,
    //     })
    //     if(response)
    // }
  },
  methods: {
    async changeInfo() {
      const UPDATE_PROFILE = gql`
        mutation ($id: Int, $name: String, $email: String) {
          UsersUpdate(users: { id: $id, name: $name, email: $email }) {
            id
            name
            email
            user_role {
              type
            }
          }
        }
      `;
      const response = await request(this.$store.state.appUrl, UPDATE_PROFILE, {
        id: +JSON.parse(localStorage['userData'])?.id,
        name: this.userData.name,
        email: this.userData.email,
      });
      this.$store.commit("setUserData", response.UsersUpdate);
      if(response.UsersUpdate?.id){
          this.$swal.fire({
              text: this.$t('change_info_success'),
              icon: "success",
              showConfirmButton: false,
              timer: 1500,
          })
      }
      else{
          this.$swal.fire({
              text: this.$t('change_info_fail'),
              icon: "warning",
              showConfirmButton: false,
              timer: 1500,
          })
      }
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>
